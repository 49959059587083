<template>
  <list-template
      :total="total"
      :table-data="tableData"
      :table-config="tableConfig"
      has-back
      @onChangePage="changeCurrentPage"
  ></list-template>
</template>

<script>
export default {
	_config:{"route":{"path":"attendance","meta":{"title":"考勤信息"}}},
  data(){
    return {
      // 表格配置
      tableConfig: [
        {
          prop:"time_start",
          label:"请假开始时间"
        },
        {
          prop:"end_start",
          label:"请假结束时间"
        },
        {
          prop:"leave_type",
          label:"请假类型"
        },
        {
          prop:"is_out_school",
          label:"是否出校"
        },
        {
          prop:"leave_reason",
          label:"原因"
        },
        {
          prop:"leave_submitter",
          label:"提交人"
        }
      ],
      // 当前页码
      page: 1,
      total: 0,
      // 表格中的数据
      tableData:[],
      id:null
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods:{
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e;
      this.getData();
    },
    getData(){
      this.$_axios.get("/students/attendance",{params: {id: this.id,page:this.page}}).then(res=>{
        let {data} = res.data;
        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      })
    }
  }
}
</script>

<style scoped>

</style>
